export default defineNuxtPlugin(() => {
  const googleAnalyticsId = useRuntimeConfig().public.googleAnalyticsId as string

  if (!googleAnalyticsId) {
    throw new Error('You must provide googleAnalyticsId in runtimeConfig when extending this layer.')
  }

  const { proxy } = useScriptGoogleAnalytics({
    id: googleAnalyticsId
  })

  return {
    provide: {
      gtag: proxy.gtag
    }
  }
})
