import { posthog } from 'posthog-js'

export default defineNuxtPlugin(() => {
  if (useRuntimeConfig().public.target !== 'production'){
    return
  }
  
  const posthogClient = posthog.init('phc_aN29hFsEOSp1U2xfwHxAvMckIKeBZz5u87NX4C6sUzu', {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'identified_only',
    capture_pageview: false,
    autocapture: false,
    disable_session_recording: true,
    // debug: true
  })
  
  // const router = useRouter()

  // router.afterEach((to) => {
  //   nextTick(() => {
  //     posthog.capture('$pageview', {
  //       current_url: to.fullPath
  //     })
      
  //     if (to.fullPath.includes('neues-kennzeichen')) {
  //       posthogClient?.startSessionRecording()
  //     }
  //   })
  // })


  return {
    provide: {
      posthog: posthogClient
    }
  }
})
