
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as agb7FiLjDb_1XZC50MXkbiQMEovqE3Q6TBhFfkhTLAdY_45gMeta } from "/vercel/path0/layers/base/pages/agb.vue?macro=true";
import { default as daten_45korrigierenHaBdxwkBz96_KCo_456hyaY_U0ybzXGFHzDgVLFhedYY4Meta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten-korrigieren.vue?macro=true";
import { default as finJulbLpg2tMx0KQDc5R0hIAehjD7AGTzvkxs_455H4lkEkMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/fin.vue?macro=true";
import { default as generellQsKywR7P2NVXzmM2Cch7slygbEsPu1JRHVYOaWK_45IOAMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/generell.vue?macro=true";
import { default as reservierung_QrOAjTGYzaoL92X0p_RaH64b65_45D477g19xTr_45NZdsMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/reservierung.vue?macro=true";
import { default as sicherheitscode_45kennzeichen_45hintenFZe_c6VT2qNcfPT3T4oq97RgNqxH8y9A0lcP4rr4PpAMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-hinten.vue?macro=true";
import { default as sicherheitscode_45kennzeichen_45vorneMbjDDmfNtUYFWWs7zKIaQVp4SqfejQd0LzxQhgnix_QMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-vorne.vue?macro=true";
import { default as sicherheitscode_45zb1NdapwoaLcNqbGgBkdr9M0sRgJgdKEbmit7KzX_5wd8MMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-zb1.vue?macro=true";
import { default as indexrGLomjoebax1VtezmPonJOtoNwftFkNMdV_0A5wzuKEMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/index.vue?macro=true";
import { default as orderpBKGXDg7A4zU7NmeBt6HQiPwj9kzEhBSsVh7a0P827oMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/order.vue?macro=true";
import { default as fertigUTtA2GigRgnq86IakSWByKDaccXKK1gACyPlIeD8dFoMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/fertig.vue?macro=true";
import { default as index6hRTDNBW1v8oMxcpbeLaF4tfphsBzj65BnGHhJP3lPkMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/index.vue?macro=true";
import { default as sc_45hintenzQU6tm8_45IDQI4pQQEQKl3W8yJXVr9hTEiPkmloyWBGMMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-hinten.vue?macro=true";
import { default as sc_45vorn87sUtba03Z0MnJicBRkjPxHlxPxw8biljfacYHCw02IMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-vorn.vue?macro=true";
import { default as sc_45zb1ChLcc2KOc28z4QdagG8hptqFVd_YoFzP6K1oRmrBh2kMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-zb1.vue?macro=true";
import { default as zb1vAflX53Yr9YsbIczHWtSmaZ63LtSNyg49HY9XY1QXssMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/zb1.vue?macro=true";
import { default as vorbereitungGAubhjxZ2aU37PCpBnqpIBUbc7k4ynBRfWqVZSkTHl4Meta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/vorbereitung.vue?macro=true";
import { default as dataFi0tmZjY_45oq2Ku8RFF_FakPErY6J_4539TPX3s7ftyv20Meta } from "/vercel/path0/apps/abmeldung-digital/pages/data.vue?macro=true";
import { default as datenschutzr_456TXbFpdB_45ErFKNiC_4509FQ0sMntJ0DovoqteC0d_45IsMeta } from "/vercel/path0/layers/base/pages/datenschutz.vue?macro=true";
import { default as impressumiknixS_3mKoW2_TGW_456rsz_4540OtD_C2IQ7akYvDGSRAMeta } from "/vercel/path0/layers/base/pages/impressum.vue?macro=true";
import { default as index0bClNungEVEDWU7q9sDEU_45rYSPTOvRFuJnB1ouV6208Meta } from "/vercel/path0/apps/abmeldung-digital/pages/index.vue?macro=true";
import { default as _91district_93H81AK0MZUTWE_45yuYABd1jXIxTEPjU3yH5Bq6jQRJLsUMeta } from "/vercel/path0/apps/abmeldung-digital/pages/landkreis/[district].vue?macro=true";
import { default as landkreiseRCiyRSfJzIBS8Afk0FLaAJORsJJY_4561yYrHofcifogQMeta } from "/vercel/path0/apps/abmeldung-digital/pages/landkreise.vue?macro=true";
import { default as loginDRRot0rRn8kJ_r997Loc_veVr5Is7pPwTk_X7RLTGgUMeta } from "/vercel/path0/apps/abmeldung-digital/pages/login.vue?macro=true";
import { default as orderIOWJNqMniN1EL_45ypRAevqZ3agh_xxFZpQ1LGKafQa_YMeta } from "/vercel/path0/layers/ausserbetriebsetzung/pages/order.vue?macro=true";
import { default as _91city_93zzI9QgSkK9qDgRfedwujcvvBxIe0d1QaiayGYJgURnMMeta } from "/vercel/path0/apps/abmeldung-digital/pages/stadt/[city].vue?macro=true";
import { default as staedte39i2ql1pwFyW_45rSCpZTSwWPGzhdYOp_45AFyN3ApULUc8Meta } from "/vercel/path0/apps/abmeldung-digital/pages/staedte.vue?macro=true";
import { default as testa1C8FUUEvythUnmGo7q5cXbIz3ypntox6UGKTioWX0MMeta } from "/vercel/path0/apps/abmeldung-digital/pages/test.vue?macro=true";
import { default as wartungdIFHjBYzCsK7z_Jeet_0qVNh3WyRYM2IR1hJBd2nzKwMeta } from "/vercel/path0/apps/abmeldung-digital/pages/wartung.vue?macro=true";
import { default as widerrufsrechtgGuMgqxaZuqvKWe74ZVPDU_45Z_hvg_x_45c7eSYdwewh60Meta } from "/vercel/path0/layers/base/pages/widerrufsrecht.vue?macro=true";
import { default as _91city_93ktxeTkIEGQdm0BJ4jmbFfb74lA8yGnG9Y2mZstUvPAUMeta } from "/vercel/path0/apps/abmeldung-digital/pages/zulassungsstelle/[city].vue?macro=true";
export default [
  {
    name: "agb",
    path: "/agb",
    meta: agb7FiLjDb_1XZC50MXkbiQMEovqE3Q6TBhFfkhTLAdY_45gMeta || {},
    component: () => import("/vercel/path0/layers/base/pages/agb.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-korrigieren",
    path: "/ausserbetriebsetzung/daten-korrigieren",
    meta: daten_45korrigierenHaBdxwkBz96_KCo_456hyaY_U0ybzXGFHzDgVLFhedYY4Meta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten-korrigieren.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-fin",
    path: "/ausserbetriebsetzung/daten/fin",
    meta: finJulbLpg2tMx0KQDc5R0hIAehjD7AGTzvkxs_455H4lkEkMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/fin.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-generell",
    path: "/ausserbetriebsetzung/daten/generell",
    meta: generellQsKywR7P2NVXzmM2Cch7slygbEsPu1JRHVYOaWK_45IOAMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/generell.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-reservierung",
    path: "/ausserbetriebsetzung/daten/reservierung",
    meta: reservierung_QrOAjTGYzaoL92X0p_RaH64b65_45D477g19xTr_45NZdsMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/reservierung.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-sicherheitscode-kennzeichen-hinten",
    path: "/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-hinten",
    meta: sicherheitscode_45kennzeichen_45hintenFZe_c6VT2qNcfPT3T4oq97RgNqxH8y9A0lcP4rr4PpAMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-hinten.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-sicherheitscode-kennzeichen-vorne",
    path: "/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-vorne",
    meta: sicherheitscode_45kennzeichen_45vorneMbjDDmfNtUYFWWs7zKIaQVp4SqfejQd0LzxQhgnix_QMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-kennzeichen-vorne.vue")
  },
  {
    name: "ausserbetriebsetzung-daten-sicherheitscode-zb1",
    path: "/ausserbetriebsetzung/daten/sicherheitscode-zb1",
    meta: sicherheitscode_45zb1NdapwoaLcNqbGgBkdr9M0sRgJgdKEbmit7KzX_5wd8MMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/daten/sicherheitscode-zb1.vue")
  },
  {
    name: "ausserbetriebsetzung",
    path: "/ausserbetriebsetzung",
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/index.vue")
  },
  {
    name: "ausserbetriebsetzung-order",
    path: "/ausserbetriebsetzung/order",
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/order.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-fertig",
    path: "/ausserbetriebsetzung/upload/fertig",
    meta: fertigUTtA2GigRgnq86IakSWByKDaccXKK1gACyPlIeD8dFoMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/fertig.vue")
  },
  {
    name: "ausserbetriebsetzung-upload",
    path: "/ausserbetriebsetzung/upload",
    meta: index6hRTDNBW1v8oMxcpbeLaF4tfphsBzj65BnGHhJP3lPkMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/index.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-sc-hinten",
    path: "/ausserbetriebsetzung/upload/sc-hinten",
    meta: sc_45hintenzQU6tm8_45IDQI4pQQEQKl3W8yJXVr9hTEiPkmloyWBGMMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-hinten.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-sc-vorn",
    path: "/ausserbetriebsetzung/upload/sc-vorn",
    meta: sc_45vorn87sUtba03Z0MnJicBRkjPxHlxPxw8biljfacYHCw02IMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-vorn.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-sc-zb1",
    path: "/ausserbetriebsetzung/upload/sc-zb1",
    meta: sc_45zb1ChLcc2KOc28z4QdagG8hptqFVd_YoFzP6K1oRmrBh2kMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/sc-zb1.vue")
  },
  {
    name: "ausserbetriebsetzung-upload-zb1",
    path: "/ausserbetriebsetzung/upload/zb1",
    meta: zb1vAflX53Yr9YsbIczHWtSmaZ63LtSNyg49HY9XY1QXssMeta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/upload/zb1.vue")
  },
  {
    name: "ausserbetriebsetzung-vorbereitung",
    path: "/ausserbetriebsetzung/vorbereitung",
    meta: vorbereitungGAubhjxZ2aU37PCpBnqpIBUbc7k4ynBRfWqVZSkTHl4Meta || {},
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/ausserbetriebsetzung/vorbereitung.vue")
  },
  {
    name: "data",
    path: "/data",
    component: () => import("/vercel/path0/apps/abmeldung-digital/pages/data.vue")
  },
  {
    name: "datenschutz",
    path: "/datenschutz",
    meta: datenschutzr_456TXbFpdB_45ErFKNiC_4509FQ0sMntJ0DovoqteC0d_45IsMeta || {},
    component: () => import("/vercel/path0/layers/base/pages/datenschutz.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    meta: impressumiknixS_3mKoW2_TGW_456rsz_4540OtD_C2IQ7akYvDGSRAMeta || {},
    component: () => import("/vercel/path0/layers/base/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    meta: index0bClNungEVEDWU7q9sDEU_45rYSPTOvRFuJnB1ouV6208Meta || {},
    component: () => import("/vercel/path0/apps/abmeldung-digital/pages/index.vue")
  },
  {
    name: "landkreis-district",
    path: "/landkreis/:district()",
    meta: _91district_93H81AK0MZUTWE_45yuYABd1jXIxTEPjU3yH5Bq6jQRJLsUMeta || {},
    component: () => import("/vercel/path0/apps/abmeldung-digital/pages/landkreis/[district].vue")
  },
  {
    name: "landkreise",
    path: "/landkreise",
    meta: landkreiseRCiyRSfJzIBS8Afk0FLaAJORsJJY_4561yYrHofcifogQMeta || {},
    component: () => import("/vercel/path0/apps/abmeldung-digital/pages/landkreise.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/vercel/path0/apps/abmeldung-digital/pages/login.vue")
  },
  {
    name: "order",
    path: "/order",
    component: () => import("/vercel/path0/layers/ausserbetriebsetzung/pages/order.vue")
  },
  {
    name: "stadt-city",
    path: "/stadt/:city()",
    meta: _91city_93zzI9QgSkK9qDgRfedwujcvvBxIe0d1QaiayGYJgURnMMeta || {},
    component: () => import("/vercel/path0/apps/abmeldung-digital/pages/stadt/[city].vue")
  },
  {
    name: "staedte",
    path: "/staedte",
    meta: staedte39i2ql1pwFyW_45rSCpZTSwWPGzhdYOp_45AFyN3ApULUc8Meta || {},
    component: () => import("/vercel/path0/apps/abmeldung-digital/pages/staedte.vue")
  },
  {
    name: "test",
    path: "/test",
    component: () => import("/vercel/path0/apps/abmeldung-digital/pages/test.vue")
  },
  {
    name: "wartung",
    path: "/wartung",
    component: () => import("/vercel/path0/apps/abmeldung-digital/pages/wartung.vue")
  },
  {
    name: "widerrufsrecht",
    path: "/widerrufsrecht",
    meta: widerrufsrechtgGuMgqxaZuqvKWe74ZVPDU_45Z_hvg_x_45c7eSYdwewh60Meta || {},
    component: () => import("/vercel/path0/layers/base/pages/widerrufsrecht.vue")
  },
  {
    name: "zulassungsstelle-city",
    path: "/zulassungsstelle/:city()",
    meta: _91city_93ktxeTkIEGQdm0BJ4jmbFfb74lA8yGnG9Y2mZstUvPAUMeta || {},
    component: () => import("/vercel/path0/apps/abmeldung-digital/pages/zulassungsstelle/[city].vue")
  }
]