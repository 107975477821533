import payload_plugin_jS_f16Tjll8WwGxVqVBoKvvQtY8dDoYVT4xWOqfcNeA from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.36.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import payload_plugin_sroBdF5_MB_bo_DEYv6WaVuUZytlFEcLk9QVZINyA8w from "/vercel/path0/node_modules/.pnpm/@pinia+colada-nuxt@0.0.4_@pinia+colada@0.13.2_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescr_jrfxaftn7efashayqkiw5xn4my/node_modules/@pinia/colada-nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_BEbN7viiw2Y7lNtPUG13KGD2BAbgFYx7xp0PQDTy7Kw from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.10.5_@upstash+redis@1.34.3_db0@0.3.1_eslint@_e2zaakva2g6xpwnaggqf5en2sy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_mY5Zty9_qP5YulEnxnM2aUy3i3SzqdcFn7dfV_tBDKA from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.10.5_@upstash+redis@1.34.3_db0@0.3.1_eslint@_e2zaakva2g6xpwnaggqf5en2sy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_1EDmjcKAlqydZkoZwgI5muATYMvbsY3o0fZSWJx5z_k from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.10.5_@upstash+redis@1.34.3_db0@0.3.1_eslint@_e2zaakva2g6xpwnaggqf5en2sy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_QYJsnadwojp3ZqaP8VOypiPjXTKf_89JmDBXPpjdMrM from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.10.5_@upstash+redis@1.34.3_db0@0.3.1_eslint@_e2zaakva2g6xpwnaggqf5en2sy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_qFhkN_6FatyvJ3j7bFyqpXLGcCJOTU3mDTci_NHQpfs from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.10.5_@upstash+redis@1.34.3_db0@0.3.1_eslint@_e2zaakva2g6xpwnaggqf5en2sy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_7PYLWAyT2Dpp2ZggnoJTG8eUWdCkpwO9OlHJ_9E08bc from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.10.5_@upstash+redis@1.34.3_db0@0.3.1_eslint@_e2zaakva2g6xpwnaggqf5en2sy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Dnx_jIhCcKDaNDLwGJL5Tk85bQp9onvxywBDSp9OLfM from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.10.5_@upstash+redis@1.34.3_db0@0.3.1_eslint@_e2zaakva2g6xpwnaggqf5en2sy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_Yr8QKXSvOBLCCz8kYqkalm6sJAUZyW1_LizV94ERmms from "/vercel/path0/apps/abmeldung-digital/.nuxt/modules/@nuxt-scripts/plugin.mjs";
import plugin_vue3_ueAJls4ohgpxHlBFH4pfLXtdGrK3LoSkhDAGt9SJraQ from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescript@5.7.2___rollup@4.36.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/vercel/path0/apps/abmeldung-digital/.nuxt/components.plugin.mjs";
import prefetch_client_Ds9pn6RaxGHnUjKitG10cRYjN_E2D6LOzdyt0cGMHZA from "/vercel/path0/node_modules/.pnpm/nuxt@3.16.0_@parcel+watcher@2.5.1_@types+node@22.10.5_@upstash+redis@1.34.3_db0@0.3.1_eslint@_e2zaakva2g6xpwnaggqf5en2sy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_P9mr5y96OIpgD0cbjCE6n6cFRtVBVkyoMC_N8zk_l1g from "/vercel/path0/node_modules/.pnpm/@pinia+colada-nuxt@0.0.4_@pinia+colada@0.13.2_pinia@2.3.0_typescript@5.7.2_vue@3.5.13_typescr_jrfxaftn7efashayqkiw5xn4my/node_modules/@pinia/colada-nuxt/dist/runtime/plugin.js";
import slideovers_MdcRJLWcohOA_lzCyjuUWgvNTA34g7eFA_VT_sWFFjQ from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.20.0_async-validator@4.2.5_axios@1.8.3_change-case@5.4.4_focus-trap@7.6.4_magicast_qwx6wcgt3wjf7qd2ngolklgmk4/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_si3FHFNvFN3zxagIfpkxiKiER61Nw9erXPtGIvv0Kf0 from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.20.0_async-validator@4.2.5_axios@1.8.3_change-case@5.4.4_focus-trap@7.6.4_magicast_qwx6wcgt3wjf7qd2ngolklgmk4/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_Kd1Zr9Sj1qH9ft3IEl6_Tqg3r8pTrFZrepu7Y_Am6zU from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.20.0_async-validator@4.2.5_axios@1.8.3_change-case@5.4.4_focus-trap@7.6.4_magicast_qwx6wcgt3wjf7qd2ngolklgmk4/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_ogirnPNPDbI7QGJ329AjqEA7Y0EO_Fw9MkxtCWnEnNI from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.36.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_C53QNzQaGyOvUTpT8_iOWEAA7hgjF_9T5Sxa5IBElCs from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.11.0_magicast@0.3.5_rollup@4.36.0_vite@6.2.2_@types+node@22.10.5_jiti@2.4.2_sass_h4q56jdtamtuxny6ebzuodteba/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_IrV_qcVsHzGBbZtHQ9m2gDaqsc5ufgUTmAqLt9NjeJY from "/vercel/path0/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.0_typescri_4rocnfjwcr4req5xgosbcyiikm/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import event_directive_0nZJC_EXHXt1owYHu2PaiclZtE_2caKcih0Ku9pUiQc from "/vercel/path0/layers/google-analytics/plugins/event-directive.ts";
import script_vBQBqaEiEhWv0WyTMwAIPShXdUJO_0uyuUOyXRkerYY from "/vercel/path0/layers/google-analytics/plugins/script.ts";
import feathers_Xa87O_d7G8vgvt9zPmut9HaGywcNTjdDhV8zrGk7Vxg from "/vercel/path0/layers/shop-api-feathers/plugins/feathers.ts";
import cookie_banner_client_U9CDJcspX6QMyB_i0XYUVaO_Z_c4oZ_3tzgZGGqMZ88 from "/vercel/path0/layers/base/plugins/cookie-banner.client.ts";
import trpc_3mnc_A5K83_7eGAhub5D0RyfmwQxDSJWln6OVfrWzE8 from "/vercel/path0/layers/base/plugins/trpc.ts";
import wartungsarbeiten_client_wyWQ6IOktpTay5c38m_Uz8QGAzjpe9izGITsoE0b38g from "/vercel/path0/layers/base/plugins/wartungsarbeiten.client.ts";
import posthog_client_2oml6AGWFDC739NVqwRmPEFqVxgr0onP_SqUR62_ALw from "/vercel/path0/apps/abmeldung-digital/plugins/posthog.client.ts";
import sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE from "/vercel/path0/apps/abmeldung-digital/plugins/sentry.client.ts";
export default [
  payload_plugin_jS_f16Tjll8WwGxVqVBoKvvQtY8dDoYVT4xWOqfcNeA,
  payload_plugin_sroBdF5_MB_bo_DEYv6WaVuUZytlFEcLk9QVZINyA8w,
  revive_payload_client_BEbN7viiw2Y7lNtPUG13KGD2BAbgFYx7xp0PQDTy7Kw,
  unhead_mY5Zty9_qP5YulEnxnM2aUy3i3SzqdcFn7dfV_tBDKA,
  router_1EDmjcKAlqydZkoZwgI5muATYMvbsY3o0fZSWJx5z_k,
  payload_client_QYJsnadwojp3ZqaP8VOypiPjXTKf_89JmDBXPpjdMrM,
  navigation_repaint_client_qFhkN_6FatyvJ3j7bFyqpXLGcCJOTU3mDTci_NHQpfs,
  check_outdated_build_client_7PYLWAyT2Dpp2ZggnoJTG8eUWdCkpwO9OlHJ_9E08bc,
  chunk_reload_client_Dnx_jIhCcKDaNDLwGJL5Tk85bQp9onvxywBDSp9OLfM,
  plugin_Yr8QKXSvOBLCCz8kYqkalm6sJAUZyW1_LizV94ERmms,
  plugin_vue3_ueAJls4ohgpxHlBFH4pfLXtdGrK3LoSkhDAGt9SJraQ,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_Ds9pn6RaxGHnUjKitG10cRYjN_E2D6LOzdyt0cGMHZA,
  plugin_P9mr5y96OIpgD0cbjCE6n6cFRtVBVkyoMC_N8zk_l1g,
  slideovers_MdcRJLWcohOA_lzCyjuUWgvNTA34g7eFA_VT_sWFFjQ,
  modals_si3FHFNvFN3zxagIfpkxiKiER61Nw9erXPtGIvv0Kf0,
  colors_Kd1Zr9Sj1qH9ft3IEl6_Tqg3r8pTrFZrepu7Y_Am6zU,
  plugin_client_ogirnPNPDbI7QGJ329AjqEA7Y0EO_Fw9MkxtCWnEnNI,
  plugin_C53QNzQaGyOvUTpT8_iOWEAA7hgjF_9T5Sxa5IBElCs,
  plugin_IrV_qcVsHzGBbZtHQ9m2gDaqsc5ufgUTmAqLt9NjeJY,
  event_directive_0nZJC_EXHXt1owYHu2PaiclZtE_2caKcih0Ku9pUiQc,
  script_vBQBqaEiEhWv0WyTMwAIPShXdUJO_0uyuUOyXRkerYY,
  feathers_Xa87O_d7G8vgvt9zPmut9HaGywcNTjdDhV8zrGk7Vxg,
  cookie_banner_client_U9CDJcspX6QMyB_i0XYUVaO_Z_c4oZ_3tzgZGGqMZ88,
  trpc_3mnc_A5K83_7eGAhub5D0RyfmwQxDSJWln6OVfrWzE8,
  wartungsarbeiten_client_wyWQ6IOktpTay5c38m_Uz8QGAzjpe9izGITsoE0b38g,
  posthog_client_2oml6AGWFDC739NVqwRmPEFqVxgr0onP_SqUR62_ALw,
  sentry_client_lpcrpmTLjShWJPDmp0fi6OOuaOcqqIWIe5I186xHGwE
]